import { type FC, useEffect, useState } from "react"

import type { OrangeBannerButton } from "common/components/banners/OrangeBanner"
import OrangeBanner from "common/components/banners/OrangeBanner"
import {
  FeatureFlag,
  useOrganization,
} from "organization/providers/OrganizationProvider"
import type { Organization } from "query/graphql"
import {
  SecurityCouncilActionBannerDocument,
  type SecurityCouncilActionBannerQuery,
  type SecurityCouncilActionBannerQueryVariables,
} from "query/graphql"
import { EXTERNAL_ROUTES } from "common/constants/routes"
import { getAccountIdParams } from "web3/helpers/accountId"
import { getChainByChainId } from "web3/helpers/findChain"
import { GrpcErrorType, fetcher } from "common/helpers/fetcher"

type Props = {
  organization: Organization
}

const SecurityCouncilMultisigAddresses = [
  "eip155:42161:0x423552c0F05baCCac5Bfa91C6dCF1dc53a0A1641",
  "eip155:42161:0xADd68bCb0f66878aB9D37a447C7b9067C5dfa941",
  "eip155:42170:0xc232ee726E3C51B86778BB4dBe61C52cC07A60F3",
]

const SecurityCouncilActionBannerWrapper: FC<Props> = (organization) => {
  const { isFeatureFlagOn } = useOrganization()

  const isSecurityCouncilBannerEnabled = isFeatureFlagOn(
    FeatureFlag.SecurityCouncilBanner,
  )

  if (!isSecurityCouncilBannerEnabled || !organization) return null

  return <SecurityCouncilActionBanner />
}

const SecurityCouncilActionBanner: FC = () => {
  const [latestCouncilTransaction, setLatestCouncilTransaction] =
    useState<any>(null)

  useEffect(() => {
    const fetchTransactions = async () => {
      const results = await Promise.all(
        SecurityCouncilMultisigAddresses.map((address) =>
          fetcher.gql<
            SecurityCouncilActionBannerQuery,
            SecurityCouncilActionBannerQueryVariables
          >({
            query: SecurityCouncilActionBannerDocument,
            variables: {
              gnosisSafeId: address,
              allTransactions: true,
              pagination: { limit: 3, offset: 0 },
            },
            omittedErrors: [GrpcErrorType.Internal],
          }),
        ),
      )

      const allTransactions = results.flatMap(
        (result) => result?.gnosisSafeTransactions ?? [],
      )
      const recentTransactions = allTransactions.filter((tx) =>
        tx.block?.timestamp
          ? new Date(tx.block.timestamp) >=
            new Date(new Date().setDate(new Date().getDate() - 14))
          : false,
      )

      if (recentTransactions.length > 0) {
        recentTransactions.sort((a, b) => {
          const aTimestamp = a.block?.timestamp
          const bTimestamp = b.block?.timestamp

          if (aTimestamp === undefined || aTimestamp === null) {
            return 1
          }

          if (bTimestamp === undefined || bTimestamp === null) {
            return -1
          }

          const aDate = new Date(aTimestamp)
          const bDate = new Date(bTimestamp)

          if (aDate < bDate) {
            return 1
          } else if (aDate > bDate) {
            return -1
          } else {
            return 0
          }
        })

        setLatestCouncilTransaction(recentTransactions[0])
      }
    }

    fetchTransactions()
  }, [])

  if (!latestCouncilTransaction) return null

  const { chainId, address } = getAccountIdParams(
    latestCouncilTransaction.safeID,
  )

  const foundChain = getChainByChainId(chainId)
  const networkShortname = foundChain?.shortName ?? ""

  const latestCouncilMethod = latestCouncilTransaction.dataDecoded?.method

  const shouldShowBanner = Boolean(latestCouncilMethod)

  // TODO: delete Override logic after Oct 10
  const showForumLinkOverride =
    latestCouncilTransaction.id ===
    "eip155:42161:0x205e3e33020c257aab211a5545f95a0fb2f1ce5d4310e73d1d761772f0c12496"

  const buttons = showForumLinkOverride
    ? [
        {
          label: "View forum post",
          url: "https://forum.arbitrum.foundation/t/security-council-emergency-action-transparency-report/26997",
          isExternal: true,
          isPrimary: false,
        },
        {
          label: "View on Safe",
          url: EXTERNAL_ROUTES.safe.txsHistory(networkShortname, address),
          isExternal: true,
          isPrimary: true,
        },
      ]
    : [
        {
          label: "Review now",
          url: EXTERNAL_ROUTES.safe.txsHistory(networkShortname, address),
          isExternal: true,
          isPrimary: true,
        },
      ]

  if (!shouldShowBanner) return null

  return (
    <OrangeBanner
      bgImageUrl="https://static.tally.xyz/2862cd0b-12eb-478c-af69-850078485d38_original.png"
      buttons={buttons as unknown as OrangeBannerButton[]}
      description={`Action: ${latestCouncilMethod}()`}
      title="Security Council action taken"
    />
  )
}

export default SecurityCouncilActionBannerWrapper
