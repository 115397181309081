import type { GetServerSidePropsContext } from "next"

import { WHITELABEL_GOVERNORS_IDS } from "whitelabel/constants/governorId"

export const getOriginRequest = (context: GetServerSidePropsContext) => {
  if (process.env.PORT === "3000") return "localhost:3000"
  if (process.env.PORT === "3001") return "localhost:3001"

  return context.req.headers.host
}

export const getIsWhiteLabel = (origin: string) => {
  if (process.env.PORT === "3001") return true

  // @ts-expect-error nothing to do
  if (WHITELABEL_GOVERNORS_IDS[origin as string]) return true

  return false
}
