import { getSepoliaChainId } from "web3/helpers/chainReference"

enum Environment {
  Prod = "https://api.tally.xyz",
  Staging = "https://api.staging.tally.xyz",
}

export type TokenWrapperSettings = {
  underlyingToken: {
    contractAddress: string
    symbol: string
    decimals: number
    chainId: string
  }
  votesToken: {
    contractAddress: string
    symbol: string
    decimals: number
    chainId: string
  }
}

// Token wrapper settings for an Organization
const TOKEN_WRAPPER_SETTINGS: Record<
  Environment,
  Record<string, TokenWrapperSettings>
> = {
  [Environment.Prod]: {
    // Testnet AGLD: https://www.tally.xyz/gov/testnet-agld
    "2420411900417279441": {
      underlyingToken: {
        contractAddress: "0xBCb84b7e0a021f6d084A9A20FdEaE61Ff298ce69",
        symbol: "AGLD",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
      votesToken: {
        contractAddress: "0xB1d04dA27906Aa42D7F0Fd426D51122B39993FCF",
        symbol: "AGLDVOTES",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
    },
    // Testnet AGLD v2: https://www.tally.xyz/gov/testnet-agld-v2
    "2424051675829372809": {
      underlyingToken: {
        contractAddress: "0xBCb84b7e0a021f6d084A9A20FdEaE61Ff298ce69",
        symbol: "AGLD",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
      votesToken: {
        contractAddress: "0xdE974aA30B2ceddBE2A21F2EC47b27BaFc864C19",
        symbol: "AGLDVOTES",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
    },
  },
  [Environment.Staging]: {
    // Testnet AGLD: https://staging.tally.xyz/gov/testnet-agld
    "2420482131982551004": {
      underlyingToken: {
        contractAddress: "0xBCb84b7e0a021f6d084A9A20FdEaE61Ff298ce69",
        symbol: "AGLD",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
      votesToken: {
        contractAddress: "0xB1d04dA27906Aa42D7F0Fd426D51122B39993FCF",
        symbol: "AGLDVOTES",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
    },

    // Testnet token wrapper: https://staging.tally.xyz/gov/testnet-token-wrapper
    "2399418815269045918": {
      underlyingToken: {
        contractAddress: "0xBCb84b7e0a021f6d084A9A20FdEaE61Ff298ce69",
        symbol: "AGLD",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
      votesToken: {
        contractAddress: "0x9146B0060e2a862B185621b4e0E7598811D90334",
        symbol: "AGLDVOTES",
        decimals: 18,
        chainId: getSepoliaChainId(),
      },
    },
  },
}

export function getTokenWrapperSettings(orgId: string) {
  return TOKEN_WRAPPER_SETTINGS[
    process.env.NEXT_PUBLIC_TALLY_API_URL as Environment
  ][orgId]
}
